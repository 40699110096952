var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "revenueProfile", staticClass: "revenueProfile" },
    [
      _vm._m(0),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.form,
                "label-position": "right",
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "code-time-range",
                  attrs: { label: "发车时间：" },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "picker-options": _vm.pickerOptions,
                      "range-separator": "至",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "default-time": ["00:00:00", "23:59:59"],
                    },
                    on: { change: _vm.selectTime },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司：" } },
                [
                  _c("companySelect", {
                    attrs: {
                      clearable: true,
                      "company-tree": _vm.companyList,
                      valuename: _vm.valuename,
                      value: _vm.value,
                    },
                    on: { getValue: _vm.getGroupId },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否含取消接驾：" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.includingCancellation,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "includingCancellation", $$v)
                        },
                        expression: "form.includingCancellation",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: true } }),
                      _c("el-option", { attrs: { label: "否", value: false } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "电召订票收现：" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.includingCallOrder,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "includingCallOrder", $$v)
                        },
                        expression: "form.includingCallOrder",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "包含", value: true },
                      }),
                      _c("el-option", {
                        attrs: { label: "不包含", value: false },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { "margin-left": "50px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.renderData },
                    },
                    [_vm._v("查询")]
                  ),
                  _vm.listFind("导出")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.exportFun },
                        },
                        [_vm._v("导出")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.openAll },
                    },
                    [_vm._v("展开")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.closeAll },
                    },
                    [_vm._v("收起")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Table", {
        ref: "table",
        attrs: {
          "table-data": _vm.tableData,
          "title-name": _vm.titleName,
          "show-summary": true,
          "table-height": 690,
          "summary-method": _vm.getSummaries,
          "tree-props": { children: "lineRevenueSummary" },
          "is-center": "left",
          isExpandChange: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "explain" }, [
      _c("header", [
        _c("i", { staticClass: "el-icon-warning" }),
        _vm._v(" 数据说明"),
      ]),
      _c("ol", [
        _c("li", [
          _vm._v(
            " 线上售票人数和线上收入：完成在线支付的订单，不含退票/改签/售后退票订单，退票/改签产生的手续费不计入线上收入。 "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }